import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useEffect, useLayoutEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { AdminBillingList } from './Components/AdminBillingList';
import { AdminEmailPreview } from './Components/AdminEmailPreview';
import { AdminUsageList } from './Components/AdminUsageList';
import { AdminUsageSummaryList } from './Components/AdminUsageSummaryList';
import AuthCallback from './Components/AuthCallback';
import { Redirect } from './Components/Common/Redirect';
import { DealRoomArtifactDetailView } from './Components/DealRoom/DealRoomArtifactDetailView';
import { DealRoomDetailView } from './Components/DealRoom/DealRoomDetailView';
import { DecisionSiteListContainer } from './Components/DealRoom/DecisionSiteList/DecisionSiteListContainer';
import { DealRoomRoot } from './Components/DealRoom/DealRoomRoot';
import EventLookup from './Components/EventLookup';
import { Home } from './Components/Home';
import { ConfigureHubSpotIntegration } from './Components/Integrations/HubSpot/ConfigureHubSpotIntegration';
import { ConfigureSalesforceIntegration } from './Components/Integrations/Salesforce/ConfigureSalesforceIntegration';
import { BaseLayout } from './Components/Layouts/BaseLayout';
import { BaseModal } from './Components/MeetingPlans/Dialogs/BaseModal';
import MeetingPlanAutoCreate from './Components/MeetingPlans/MeetingPlanAutoCreate';
import { MeetingPlanRoot } from './Components/MeetingPlans/MeetingPlanRoot';
import { NotFound } from './Components/NotFound';
import { CreateOrganization } from './Components/Organization/CreateOrganization';
import { PlansPane } from './Components/Organization/Home/PlansPane';
import { HubSpotPipelineView } from './Components/Organization/HubspotPipelineView';
import CompanyBrowser from './Components/Organization/Library/CompanyBrowser';
import ContactBrowser from './Components/Organization/Library/ContactBrowser';
import TemplateEditor from './Components/Organization/Library/Templates/TemplateEditor';
import { OrganizationHome } from './Components/Organization/OrganizationHome';
import OrganizationInviteMember from './Components/Organization/OrganizationInviteMember';
import OrganizationInvites from './Components/Organization/OrganizationInvites';
import OrganizationRequests from './Components/Organization/OrganizationRequests';
import { OrganizationRoot } from './Components/Organization/OrganizationRoot';
import { UsageReport } from './Components/Organization/OrganizationUsageReport';
import { SalesforcePipelineView } from './Components/Organization/SalesforcePipelineView';
import { Search } from './Components/Organization/Search';
import OrganizationSettings from './Components/Organization/Settings/OrganizationSettings';
import { LayoutTestPage } from './Components/Pages/LayoutTestPage';
import { Profile } from './Components/Profile';
import { StyledSpinner } from './Components/StyledSpinner';
import { UserInvites } from './Components/UserInvites';
import WelcomeWizard from './Components/Welcome/WelcomeWizard';
import { DISMISSED_HAPPENING_SOON_EVENT_IDS } from './Constants';
import {
  isConsentRequiredError,
  isGenericError,
  isLoginRequiredError,
  isUnknownOrInvalidRefreshToken,
} from './Helpers/Auth0Helpers';
import { isAxiosErrorResponse } from './Helpers/AxiosHelpers';
import { deleteValue } from './Helpers/SearchParamHelpers';
import { useInteractionMode } from './Hooks/useInteractionMode';
import { useLightOrDarkMode } from './Hooks/useLightOrDarkMode';
import { useLocalStorageState } from './Hooks/useLocalStorageState';
import { useOrganization } from './Hooks/useOrganization';
import { useUserProfile } from './Hooks/useProfile';
import { InvitesQuery, OrganizationsQuery } from './QueryNames';
import {
  InvitesApiClient,
  OrganizationsApiClient,
} from './Services/NetworkCommon';
import { AdminMeetingflowCopy } from './Components/AdminMeetingflowCopy';

export type AppProps = {
  appInsights: ApplicationInsights;
};

export const App = ({ appInsights }: AppProps) => {
  const {
    user,
    isLoading: userLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, name, given_name, family_name, phone_number, sub } = user!;

  const { user: mfUser } = useUserProfile();

  const {
    organization,
    slug: organizationSlug,
    refetch: refetchOrg,
    forbidden: orgForbidden,
    role: orgRole,
    canCreatePlans,
    isGuest: isOrgGuest,
  } = useOrganization();

  // useLayoutEffect to set user context as early as possible
  useLayoutEffect(() => {
    if (!!email) {
      appInsights.setAuthenticatedUserContext(
        email.toLowerCase(),
        organization?.slug,
        true,
      );

      Bugsnag.setUser(mfUser?.id?.toString() || sub, email, name);
      Bugsnag.addMetadata('organization', {
        id: organization?.id,
        slug: organization?.slug,
      });

      // Appears userId can not be an email address in GA4
      // https://support.google.com/analytics/answer/9267744?hl=en
      ReactGA.set({
        userId: mfUser?.id?.toString() || sub,
        contentGroup1: organization?.slug,
      });
    }
  }, [
    appInsights,
    email,
    family_name,
    given_name,
    name,
    phone_number,
    sub,
    mfUser,
    organization,
  ]);

  const client = useQueryClient();
  const [lastSessionId, setLastSessionId] = useLocalStorageState<string>(
    'LAST_SESSION_ID',
    '',
  );
  const sessionId = appInsights.context.getSessionId();
  const { currentTheme } = useLightOrDarkMode();

  const [searchParams, setSearchParams] = useSearchParams();

  const [acceptingInvite, setAcceptingInvite] = useState(false);

  const { supportsTouch } = useInteractionMode();

  const [, setDismissedHappeningSoonEventIds] = useLocalStorageState<string[]>(
    DISMISSED_HAPPENING_SOON_EVENT_IDS,
    [],
  );

  const {
    user: userProfile,
    isLoading: userProfileLoading,
    isFetched: userProfileFetched,
    refetch: refetchUser,
  } = useUserProfile();

  const {
    data: invitesData,
    isLoading: invitesLoading,
    isFetched: invitesFetched,
    refetch: refetchInvites,
  } = useQuery(InvitesQuery('RECEIVED', organizationSlug), async () => {
    const token = await getAccessTokenSilently();
    return InvitesApiClient.getInvites(
      { type: 'RECEIVED', organization: organizationSlug },
      { headers: { Authorization: `Bearer ${token}` } },
    );
  });

  const {
    data: orgsData,
    isLoading: orgsDataLoading,
    isFetched: orgsDataIsFetched,
    refetch: refetchOrgs,
  } = useQuery(OrganizationsQuery, async () => {
    const token = await getAccessTokenSilently();
    return OrganizationsApiClient.listOrganizations(
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  useEffect(() => {
    setDismissedHappeningSoonEventIds([], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // On tab focus, try and get an Auth0 token silently
    // If the user has left the tab open for a prolonged period
    // The session may have expired, causing a login_required, or consent_required error
    // If so, trigger loginWithRedirect for interactive refresh to get new tokens
    const onFocus = () => {
      getAccessTokenSilently().catch((err: unknown) => {
        if (isLoginRequiredError(err) || isConsentRequiredError(err)) {
          loginWithRedirect();
        } else if (isUnknownOrInvalidRefreshToken(err)) {
          appInsights.trackEvent({ name: 'UNKNOWN_OR_INVALID_REFRESH_TOKEN' });
          loginWithRedirect();
        } else if (isGenericError(err)) {
          appInsights.trackEvent({
            name: 'AUTH0_GENERIC_ERROR',
            properties: {
              ...err,
            },
          });
          loginWithRedirect();
        } else {
          appInsights.trackException({
            exception: err instanceof Error ? err : new Error(`${err}`),
          });
          throw err;
        }
      });
    };

    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [appInsights, getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    getAccessTokenSilently().catch((err: unknown) => {
      if (isLoginRequiredError(err) || isConsentRequiredError(err)) {
        loginWithRedirect();
      } else if (isUnknownOrInvalidRefreshToken(err)) {
        appInsights.trackEvent({ name: 'UNKNOWN_OR_INVALID_REFRESH_TOKEN' });
        loginWithRedirect();
      } else if (isGenericError(err)) {
        appInsights.trackEvent({
          name: 'AUTH0_GENERIC_ERROR',
          properties: {
            ...err,
          },
        });
        loginWithRedirect();
      } else {
        appInsights.trackException({
          exception: err instanceof Error ? err : new Error(`${err}`),
        });
        throw err;
      }
    });
  }, [appInsights, getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    if (sessionId !== lastSessionId) {
      setLastSessionId(sessionId);
      appInsights.trackEvent({
        name: 'SESSION_START',
        properties: {
          sub: user?.sub,
          name: user?.name,
          email: user?.email,
          theme: currentTheme,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          nativeWidth: window.screen.width * window.devicePixelRatio,
          nativeHeight: window.screen.height * window.devicePixelRatio,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          supportsTouch: !!supportsTouch,
          utmSource: searchParams.get('utm_source') ?? undefined,
        },
      });
    } else {
      appInsights.trackEvent({
        name: 'BROWSER_REFRESH',
        properties: {
          sub: user?.sub,
          name: user?.name,
          email: user?.email,
          theme: currentTheme,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          nativeWidth: window.screen.width * window.devicePixelRatio,
          nativeHeight: window.screen.height * window.devicePixelRatio,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          supportsTouch: !!supportsTouch,
          utmSource: searchParams.get('utm_source') ?? undefined,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useLayoutEffect(() => {
    const inviteId = searchParams.get('inviteId');
    const invite =
      !!inviteId &&
      invitesData?.data?.find((pendingInvite) => pendingInvite.id === inviteId);

    if (!acceptingInvite && invite) {
      setAcceptingInvite(true);

      getAccessTokenSilently()
        .then(async (token) => {
          try {
            await InvitesApiClient.acceptInvite(invite.id, {
              headers: { Authorization: `Bearer ${token}` },
            });

            refetchUser();
            refetchOrgs();
            refetchInvites();
            refetchOrg();

            appInsights.trackEvent({
              name: 'AUTO_ACCEPT_INVITE',
              properties: {
                inviteId,
                organizationId: organization?.id,
                utmSource: searchParams.get('utm_source') ?? undefined,
              },
            });

            setSearchParams(deleteValue(searchParams, 'inviteId'));
          } catch (err) {
            appInsights.trackEvent({
              name: 'AUTO_ACCEPT_INVITE_FAILED',
              properties: {
                inviteId,
                organizationId: organization?.id,
                utmSource: searchParams.get('utm_source') ?? undefined,
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
              },
            });
          }
        })
        .finally(() => {
          setAcceptingInvite(false);
        });
    }
  }, [
    acceptingInvite,
    appInsights,
    client,
    getAccessTokenSilently,
    invitesData?.data,
    organization?.id,
    organizationSlug,
    refetchInvites,
    refetchOrg,
    refetchOrgs,
    refetchUser,
    searchParams,
    setSearchParams,
  ]);

  const hasOrgs = !!orgsData?.data?.length;

  if (
    userLoading ||
    (userProfileLoading && !userProfileFetched) ||
    (orgsDataLoading && !orgsDataIsFetched) ||
    (invitesLoading && !invitesFetched) ||
    acceptingInvite
  ) {
    return <StyledSpinner />;
  }

  const showWelcomeWizard =
    !userProfile?.tosAccepted ||
    !userProfile?.timezone ||
    !hasOrgs ||
    (!!organizationSlug &&
      (orgForbidden ||
        !!organization?.pendingInvites?.length ||
        !!invitesData?.data?.length));

  const useFullWidthLayout =
    window.location.pathname.includes('pipeline') ||
    window.location.pathname.includes('admin-usage-summary');

  return (
    <>
      <BaseLayout fullWidth={useFullWidthLayout}>
        {showWelcomeWizard ? (
          <BaseModal
            isOpen={showWelcomeWizard}
            onDismiss={async () => {
              refetchUser();
              refetchOrgs();
              refetchInvites();
              refetchOrg();
            }}
            isBlocking
            contentPadding="0"
            styles={{
              root: {
                margin: '0 auto',
              },

              main: {
                height: 'auto',
                boxSizing: 'border-box',
                borderRadius: '.25rem',
              },
            }}
          >
            <WelcomeWizard
              organizationSlug={organizationSlug}
              userProfile={userProfile}
              orgRole={orgRole}
              refetchUserProfile={refetchUser}
              pendingInvites={invitesData?.data}
              refetchInvites={refetchInvites}
              refetchAll={() =>
                Promise.all([
                  refetchUser(),
                  refetchOrgs(),
                  refetchInvites(),
                  refetchOrg(),
                ])
              }
            />
          </BaseModal>
        ) : (
          <div style={{ height: '100%' }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/invites" element={<UserInvites />} />
              <Route path="/auth/callback" element={<AuthCallback />} />
              <Route path="/event" element={<EventLookup />} />
              <Route
                path="/organization/create"
                element={<CreateOrganization />}
              />
              <Route
                path="/organization/:organizationSlug"
                element={<OrganizationRoot />}
              >
                <Route path="search" element={<Search />} />
                <Route path="usage" element={<UsageReport />} />
                <Route
                  path="salesforce/pipeline"
                  element={<SalesforcePipelineView />}
                />
                <Route
                  path="hubspot/pipeline"
                  element={<HubSpotPipelineView />}
                />
                <Route
                  index
                  element={
                    isOrgGuest ? (
                      <Redirect to="/organization/:organizationSlug/library/plans" />
                    ) : (
                      <OrganizationHome />
                    )
                  }
                />
                <Route path="library">
                  <Route
                    index
                    element={
                      <Redirect to="/organization/:organizationSlug/library/plans" />
                    }
                  />
                  <Route path="plans" element={<PlansPane />} />
                  <Route
                    path="templates"
                    element={
                      !canCreatePlans ? (
                        <Redirect to="/organization/:organizationSlug/library/plans" />
                      ) : (
                        <TemplateEditor />
                      )
                    }
                  />
                  <Route
                    path="templates/:objectId"
                    element={
                      !canCreatePlans ? (
                        <Redirect to="/organization/:organizationSlug/library/plans" />
                      ) : (
                        <TemplateEditor />
                      )
                    }
                  />

                  <Route
                    path="companies"
                    element={
                      !canCreatePlans ? (
                        <Redirect to="/organization/:organizationSlug/library/plans" />
                      ) : (
                        <CompanyBrowser />
                      )
                    }
                  />
                  <Route
                    path="companies/:objectId"
                    element={
                      !canCreatePlans ? (
                        <Redirect to="/organization/:organizationSlug/library/plans" />
                      ) : (
                        <CompanyBrowser />
                      )
                    }
                  />

                  <Route
                    path="contacts"
                    element={
                      !canCreatePlans ? (
                        <Redirect to="/organization/:organizationSlug/library/plans" />
                      ) : (
                        <ContactBrowser />
                      )
                    }
                  />
                  <Route
                    path="contacts/:objectId"
                    element={
                      !canCreatePlans ? (
                        <Redirect to="/organization/:organizationSlug/library/plans" />
                      ) : (
                        <ContactBrowser />
                      )
                    }
                  />

                  <Route
                    path="dealrooms"
                    element={<DecisionSiteListContainer />}
                  />

                  <Route
                    path="*"
                    element={
                      <Redirect to="/organization/:organizationSlug/library/plans" />
                    }
                  />
                </Route>
                <Route path="settings" element={<OrganizationSettings />} />
                <Route path="requests" element={<OrganizationRequests />} />
                <Route path="invites" element={<OrganizationInvites />} />
                <Route path="invite" element={<OrganizationInviteMember />} />
                <Route
                  path="configuration/salesforce/:configurationId/:sObjectType"
                  element={<ConfigureSalesforceIntegration />}
                />
                <Route
                  path="configuration/hubspot/:configurationId/:objectType"
                  element={<ConfigureHubSpotIntegration />}
                />
                <Route path="dealroom/:dealRoomId" element={<DealRoomRoot />}>
                  <Route
                    path="artifact/:artifactId"
                    element={<DealRoomArtifactDetailView />}
                  />
                  <Route index element={<DealRoomDetailView />} />
                  <Route path="overview" element={<DealRoomDetailView />} />
                  <Route path="artifacts" element={<DealRoomDetailView />} />
                  <Route path="journey" element={<DealRoomDetailView />} />
                  <Route path="mutual-action-plan" element={<DealRoomDetailView />}/>
                  <Route path="config" element={<DealRoomDetailView />}/>
                  <Route
                    path="*"
                    element={
                      <Redirect to="/organization/:organizationSlug/dealroom/:dealRoomId" />
                    }
                  />
                </Route>
                <Route
                  path="plan/:meetingPlanId"
                  element={<MeetingPlanRoot />}
                />
                <Route path="plan/create" element={<MeetingPlanAutoCreate />} />
              </Route>
              <Route path="/emailPreview" element={<AdminEmailPreview />} />
              <Route path="/adminBilling" element={<AdminBillingList />} />
              <Route path="/adminUsage" element={<AdminUsageList />} />
              <Route
                path="/adminMeetingflowCopy"
                element={<AdminMeetingflowCopy />}
              />
              <Route
                path="/admin-usage-summary"
                element={<AdminUsageSummaryList />}
              />
              {import.meta.env.DEV ? (
                <Route path="/layout-test" element={<LayoutTestPage />} />
              ) : null}

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        )}
        <Toaster />
      </BaseLayout>
    </>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <StyledSpinner />,
});
