import { useAuth0 } from '@auth0/auth0-react';
import { DEALROOMS_COLORS } from '../Themes/Themes';

import {
  FontWeights,
  PrimaryButton,
  mergeStyles,
  TextField,
} from '@fluentui/react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';

async function copyMeetingflow(
  bearerToken: string,
  sourceOrgId: number,
  sourceMeetingPlanId: string,
  destinationOrgId: number,
) {
  try {
    const response = await fetch('/api/admin/plans/copy', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sourceOrgId,
        sourceMeetingPlanId,
        destinationOrgId,
      }),
    });
    const responseData = await response.text();
    console.info(responseData);
    toast.success(
      `Meetingflow copied from Source ID ${sourceOrgId} to Destination ID ${destinationOrgId}`,
    );
    location.reload();
  } catch (error) {
    toast.error('Error, Meetingflow not copied!');
  }
}

export function AdminMeetingflowCopy() {
  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();

  const [sourceOrgId, setSourceOrgId] = useState<number>();
  const [sourceMeetingPlanId, setSourceMeetingPlanId] = useState<string>();
  const [destinationOrgId, setDestinationOrgId] = useState<number>();

  const meetingflowCopyClass = mergeStyles({
    backgroundColor: isDark
      ? DEALROOMS_COLORS.themeSecondary
      : DEALROOMS_COLORS.white,
    padding: '1rem',
    borderRadius: '.25rem',
    overflowY: 'scroll',
    height: '100%',

    '> .controls': {
      padding: '.25rem',
      display: 'flex',
      marginBottom: '.5rem',

      '.ms-Checkbox-text': {
        fontWeight: FontWeights.semibold,
      },
    },
  });

  return (
    <div className={meetingflowCopyClass}>
      <div className="controls">
        <TextField
          type="number"
          label="Source Org ID"
          value={sourceOrgId?.toString()}
          onChange={(e, newValue) => setSourceOrgId(parseInt(newValue || ''))}
        />
        <TextField
          label="Source Meeting Plan ID"
          value={sourceMeetingPlanId?.toString()}
          onChange={(e, newValue) => setSourceMeetingPlanId(newValue || '')}
        />
        <TextField
          type="number"
          label="Destination Org ID"
          value={destinationOrgId?.toString()}
          onChange={(e, newValue) =>
            setDestinationOrgId(parseInt(newValue || ''))
          }
        />
        <PrimaryButton
          style={{
            marginLeft: '1.5rem',
          }}
          onClick={async () => {
            if (sourceOrgId && sourceMeetingPlanId && destinationOrgId) {
              copyMeetingflow(
                await getAccessTokenSilently(),
                sourceOrgId,
                sourceMeetingPlanId,
                destinationOrgId,
              );
            } else {
              toast.error('Please fill in all fields');
            }
          }}
        >
          Copy Meetingflow
        </PrimaryButton>
      </div>
    </div>
  );
}
