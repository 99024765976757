import { IGroup } from '@fluentui/react';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { DateTime } from 'luxon';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';

export const getAdjustedEventStartTimeString = (
  event: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>,
) => getAdjustedEventStartDateTime(event).toISO();

export const getAdjustedEventEndTimeString = (
  event: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>,
) => getAdjustedEventEndDateTime(event).toISO();

export const getAdjustedEventStartDateTime = (
  event: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>,
) => {
  return DateTime.fromISO(event.startTime, { setZone: true }).setZone('local', {
    keepLocalTime: event.isAllDay,
  });
};

export const getAdjustedEventEndDateTime = (
  event: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>,
) => {
  return DateTime.fromISO(event.startTime, { setZone: true }).setZone('local', {
    keepLocalTime: event.isAllDay,
  });
};

export const toIGroups = (
  events?: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>[],
): IGroup[] => {
  if (!events?.length) {
    return [];
  }
  let dayOfYear = getAdjustedEventStartDateTime(events[0]).ordinal;
  const groups: IGroup[] = [
    {
      key: `${dayOfYear}`,
      name: humanizeDateTime(getAdjustedEventStartTimeString(events[0]), {
        displayComponents: ['date'],
        dateFormatOptions: {
          weekday: 'long',
          month: 'short',
          day: 'numeric',
          year: '2-digit',
        },
      }),
      startIndex: 0,
      count: 0,
      level: 0,
    },
  ];
  events.forEach((event, idx) => {
    const latestDayOfYear = getAdjustedEventStartDateTime(event).ordinal;
    if (latestDayOfYear === dayOfYear) {
      groups[groups.length - 1]!.count += 1;
    } else {
      groups.push({
        key: `${latestDayOfYear}`,
        name: humanizeDateTime(getAdjustedEventStartTimeString(event), {
          displayComponents: ['date'],
          dateFormatOptions: {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            year: '2-digit',
          },
        }),
        startIndex: idx,
        count: 1,
        level: 0,
      });
    }
    dayOfYear = latestDayOfYear;
  });
  return groups;
};

export const groupEventsByDay = (
  events?: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>[],
) => {
  if (!events?.length) {
    return [];
  }
  const groups: {
    key: string;
    name: string;
    startIndex: number;
    count: number;
    level: number;
    items: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>[];
  }[] = [];
  let dayOfYear = getAdjustedEventStartDateTime(events[0]).ordinal;
  groups.push({
    key: `${dayOfYear}`,
    name: humanizeDateTime(getAdjustedEventStartTimeString(events[0]), {
      displayComponents: ['date'],
      dateFormatOptions: {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
        year: '2-digit',
      },
    }),
    startIndex: 0,
    count: 1,
    level: 0,
    items: [events[0]],
  });
  events.slice(1).forEach((event, idx) => {
    const latestDayOfYear = getAdjustedEventStartDateTime(event).ordinal;
    if (latestDayOfYear === dayOfYear) {
      const lastGroup = groups[groups.length - 1]!;
      lastGroup.count += 1;
      lastGroup.items.push(event);
    } else {
      groups.push({
        key: `${latestDayOfYear}`,
        name: humanizeDateTime(getAdjustedEventStartTimeString(event), {
          displayComponents: ['date'],
          dateFormatOptions: {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            year: '2-digit',
          },
        }),
        startIndex: idx,
        count: 1,
        level: 0,
        items: [event],
      });
    }
    dayOfYear = latestDayOfYear;
  });
  return groups;
};
