import { IColumn, SelectionMode } from '@fluentui/react';
import { EMPTY_ARRAY } from '../../../Constants';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { StyledDetailsList } from '../../StyledDetailsList';
import { AxiosResponse } from 'axios';
import { ListDealRoomsData } from '@meetingflow/common/Api/data-contracts';

export const DecisionSiteListView = ({
    dealRoomsData,
    dealRoomsFetching,
    dealRoomsFetched,
    columns,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    parentRef,
    loadMoreRef,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dealRoomsData: AxiosResponse<ListDealRoomsData, any>[] | undefined
    dealRoomsFetching: boolean;
    dealRoomsFetched: boolean;
    columns: IColumn[];
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    fetchNextPage: () => void;
    parentRef: React.RefObject<HTMLDivElement>;
    loadMoreRef: (node?: Element | null) => void;
  }) => {

    return (
    <div
      ref={parentRef}
      style={{
        maxHeight: 'calc(100% - 4rem)',
        overflowY: 'auto',
        height: `calc(100% - 4rem)`,
        backgroundColor: MEETINGFLOW_COLORS.white
      }}
    >
      {(() => {
        if (dealRoomsData?.length) {
          return dealRoomsData.map((page, index) => (
            <StyledDetailsList
              key={index}
              enableShimmer={false}
              shimmerLines={25}
              items={page?.data || EMPTY_ARRAY}
              selectionMode={SelectionMode.none}
              columns={columns}
              isHeaderVisible={index === 0}
              className="small-header"
            />
          ));
        }

        return (
          <StyledDetailsList
            enableShimmer={!dealRoomsFetched}
            shimmerLines={25}
            items={EMPTY_ARRAY}
            selectionMode={SelectionMode.none}
            columns={columns}
            isHeaderVisible={false}
            className="small-header"
          />
        );
      })()}

      <div ref={loadMoreRef}>
        <button
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          style={{
            display: hasNextPage ? 'block' : 'none',
            margin: '0 auto',
          }}
        >
          {isFetchingNextPage
            ? 'Loading more...'
            : hasNextPage
              ? 'Load More'
              : 'Nothing more to load'}
        </button>
        <div>
          {dealRoomsFetching && !isFetchingNextPage ? 'Background Updating...' : null}
        </div>
      </div>
    </div>
    );
}

