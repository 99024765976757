// Vite doesn't define `global` like webpack does, make sure it's set as react-floater needs it!
window.global ||= window;

import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { registerIcons } from '@fluentui/react';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { TextRecognitionIcon } from '@fluentui/react-icons-mdl2';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React, { PropsWithChildren } from 'react';
import * as ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import Auth0ProviderWithHistory from './Components/Auth/Auth0ProviderWithHistory';
import { ErrorComponent } from './Components/ErrorComponent';
import { Logout } from './Components/Logout';
import { setAppInsights } from './Services/NetworkCommon';
import * as serviceWorker from './serviceWorker';

import './index.scss';
import './Styles/blueprint/core.scss';
import './Styles/blueprint/datetime.scss';
import './Styles/blueprint/datetime2.scss';
import './Styles/fonts/fonts.scss';
import { Duration } from 'luxon';
import {
  isAxiosErrorResponse,
  isBadRequest,
  isForbiddenError,
} from './Helpers/AxiosHelpers';

export const GOOGLE_ANALYTICS_ID = 'G-XG41C31NN4';
export const GOOGLE_TAG_MANAGER_ID = 'GTM-NVHKD3JL';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env
      .VITE_APPLICATIONINSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableTelemetry: !import.meta.env.PROD,
  },
});
appInsights.loadAppInsights();

setAppInsights(appInsights);

ReactGA.initialize([
  {
    trackingId: GOOGLE_ANALYTICS_ID,
  },
  {
    trackingId: GOOGLE_TAG_MANAGER_ID,
  },
]);

if (import.meta.env.VITE_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    appVersion: import.meta.env.VITE_BUILD_VERSION,
    releaseStage: import.meta.env.MODE,
    enabledReleaseStages: ['production'],
    appType: 'client',
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    appVersion: import.meta.env.VITE_BUILD_VERSION,
    releaseStage: import.meta.env.MODE,
    enabledReleaseStages: ['production'],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
      cacheTime: Duration.fromObject({ minutes: 30 }).as('milliseconds'),
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
      retryDelay: (failureCount, error) => {
        if (isAxiosErrorResponse(error, 429)) {
          return (
            Duration.fromObject({ seconds: 1 }).as('milliseconds') *
            Math.min(2 ** failureCount, 60)
          );
        }
        return (
          Duration.fromObject({ seconds: 1 }).as('milliseconds') *
          Math.min(2 ** failureCount, 3)
        );
      },
    },
  },
});

registerIcons({
  icons: {
    TextRecognition: <TextRecognitionIcon />,
    SectionExpanded: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        fill="currentColor"
        stroke="currentColor"
      >
        <polygon points="4,4 16,4 10,16" />
      </svg>
    ),
    SectionCollapsed: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        fill="currentColor"
        stroke="currentColor"
      >
        <polygon points="4,4 16,10 4,16" />
      </svg>
    ),
    AISparkle: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path
          d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917   c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9   c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904   C315.08,148.422,272.014,131.551,247.355,106.9z"
          style={{ fill: 'currentColor', stroke: 'currentColor' }}
        />
        <path
          d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872   c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864   c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82   C447.939,329.14,422.201,319.061,407.471,304.339z"
          style={{ fill: 'currentColor', stroke: 'currentColor' }}
        />
        <path
          d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027   c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626   c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019   C159.024,395.966,150.555,374.351,146.352,354.702z"
          style={{ fill: 'currentColor', stroke: 'currentColor' }}
        />
      </svg>
    ),
  },
});

initializeIcons();
initializeFileTypeIcons();

const container = document.getElementById('root');

const bugsnagReactPlugin = Bugsnag.getPlugin('react');
const ErrorBoundary = bugsnagReactPlugin
  ? bugsnagReactPlugin.createErrorBoundary(React)
  : ({ children }: PropsWithChildren<unknown>) => <>{children}</>;

ReactDOM.createRoot(container!).render(
  <React.StrictMode>
    <BrowserRouter>
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <Auth0ProviderWithHistory>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <Toaster
                containerStyle={{
                  zIndex: 999999999,
                }}
              />
              <Routes>
                <Route
                  key="/logout"
                  path="/logout"
                  element={<Logout appInsights={appInsights} />}
                />
                <Route
                  key="*"
                  path="*"
                  element={<App appInsights={appInsights} />}
                />
              </Routes>
            </ErrorBoundary>
          </Auth0ProviderWithHistory>
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
