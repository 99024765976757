import {
  DefaultButton,
  FontSizes,
  mergeStyles,
  NeutralColors,
  Toggle,
} from '@fluentui/react';
import { useMemo } from 'react';
import {
  deleteValue,
  setValue,
} from '../../../Helpers/SearchParamHelpers';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { AsyncDefaultButton } from '../../HOC/AsyncButton';

// Subcomponents
export const DecisionSiteListControls = ({
  searchParams,
  setSearchParams,
  canCreatePlans,
  onCreateNewDealRoom,
}: {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  canCreatePlans: boolean;
  onCreateNewDealRoom: () => Promise<void>;
}) => {
  const tableControlsClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.25rem',
    margin: '1rem .25rem .5rem .25rem',
    overflowX: 'auto',
    overflowY: 'hidden',
  });

  const clearButtonStyles = useMemo(
    () => ({
      root: {
        height: '1.5rem',
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
        border: 'none !important',
        transition: '.3s ease-in-out all',
        'div:after': {
          display: 'none !important',
        },
      },
      rootDisabled: {
        backgroundColor: 'transparent',
      },
      rootHovered: {
        backgroundColor: MEETINGFLOW_COLORS.purpleDarker,
      },
      icon: {
        color: 'white',
      },
    }),
    [],
  );

  return (
    <div className={tableControlsClass}>
      <Toggle
        label={'Active'}
        checked={searchParams.has('active') && searchParams.get('active') === 'true'}
        onChange={(e, checked) => {
          setSearchParams(
            checked
              ? setValue(searchParams, 'active', 'true')
              : deleteValue(searchParams, 'active')
          );
        }}
        styles={{
          root: {
            display: 'inline-block',
            margin: 0,
            flexGrow: 1,
            transition: '.3s ease-in-out all',
            height: '1.5rem',
            position: 'relative',
            minWidth: '7rem',

            '.ms-Toggle-innerContainer': {
              display: 'inline-block',
              position: 'relative',
              top: '.2rem',

              button: {
                transition: 'all .3s ease-in-out',
                backgroundColor: MEETINGFLOW_COLORS.purpleGrey,
              },
            },
            '.ms-Toggle-thumb': {
              backgroundColor:
                searchParams.has('active') && searchParams.get('active') === 'true'
                  ? MEETINGFLOW_COLORS.purpleTertiary
                  : NeutralColors.gray70,
            },
            'button:disabled .ms-Toggle-thumb': {
              backgroundColor: NeutralColors.gray70,
            },
            'button:hover': {
              backgroundColor: NeutralColors.gray40,
            },
            button: {},
          },
          label: {
            padding: 0,
            height: '1.5rem',
            lineHeight: '1.5rem',
            fontSize: FontSizes.mini,
            color: NeutralColors.gray120,
            maxWidth: '5rem',
            marginRight: '.25rem',
          },
          pill: {
            backgroundColor: NeutralColors.gray40,
            outlineWidth: `0 !important`,
            borderWidth: `0 !important`,
          },
        }}
      />

      <Toggle
        label={'Mine'}
        checked={searchParams.has('my') && searchParams.get('my') === 'true'}
        onChange={(e, checked) => {
          setSearchParams(
            checked
              ? setValue(searchParams, 'my', 'true')
              : deleteValue(searchParams, 'my')
          );
        }}
        styles={{
          root: {
            display: 'inline-block',
            margin: 0,
            flexGrow: 1,
            transition: '.3s ease-in-out all',
            height: '1.5rem',
            position: 'relative',
            minWidth: '7rem',

            '.ms-Toggle-innerContainer': {
              display: 'inline-block',
              position: 'relative',
              top: '.2rem',

              button: {
                transition: 'all .3s ease-in-out',
                backgroundColor: NeutralColors.gray180
              },
            },
            '.ms-Toggle-thumb': {
              backgroundColor:
                searchParams.has('my') && searchParams.get('my') === 'true'
                  ? MEETINGFLOW_COLORS.purpleTertiary
                  : NeutralColors.gray70,
            },
            'button:disabled .ms-Toggle-thumb': {
              backgroundColor: NeutralColors.gray70,
            },
            'button:hover': {
              backgroundColor: NeutralColors.gray40,
            },
            button: {},
          },
          label: {
            padding: 0,
            height: '1.5rem',
            lineHeight: '1.5rem',
            fontSize: FontSizes.mini,
            color: NeutralColors.gray120,
            maxWidth: '5rem',
            marginRight: '.25rem',
          },
          pill: {
            backgroundColor: NeutralColors.gray40,
            outlineWidth: `0 !important`,
            borderWidth: `0 !important`,
          },
        }}
      />

      <DefaultButton
        iconProps={{ iconName: 'ClearFilter' }}
        label="Clear filters"
        alt="Clear filters"
        disabled={Array.from(searchParams.keys()).length === 0}
        onClick={() => setSearchParams(new URLSearchParams())}
        styles={clearButtonStyles}
      />

      <AsyncDefaultButton
        iconProps={{ iconName: 'Add' }}
        label="New"
        text="New"
        alt="New"
        disabled={!canCreatePlans}
        styles={clearButtonStyles}
        onClick={onCreateNewDealRoom}
      />
    </div>
  );
};