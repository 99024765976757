import { useAuth0 } from '@auth0/auth0-react';
import { Facepile, IColumn, Spinner, Text } from '@fluentui/react';
import { DealRoom } from '@meetingflow/common/Api/data-contracts';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { collectToRecord,} from '../../../Helpers/SearchParamHelpers';
import { useNewDealRoomDialog } from '../../../Hooks/Modals/DealRoom/useNewDealRoomDialog';
import { useConfirmationDialog } from '../../../Hooks/Modals/useConfirmationDialog';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useTitle } from '../../../Hooks/useTitle';
import { OrganizationDealRoomsQuery } from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { AsyncIconButton } from '../../HOC/AsyncButton';
import { ContactCard } from '../../MeetingPlans/MeetingPlanAttendees';
import { DecisionSiteListControls } from './DecisionSiteListControls';
import { DecisionSiteListView } from './DecisionSiteListView';
import { getDomain } from '@meetingflow/common/StringHelpers';
import { DeleteIconProps } from '../../../utils/iconProps';

// Number of Decision Sites to fetch per page
const limit = 50;

export const DecisionSiteListContainer = () => {
  useTitle('Decision Sites');

  // Get organization context
  const {
    slug,
    isLoading,
    isAdmin,
    canCreatePlans,
    internalDomains,
    hasEntitlement,
  } = useOrganization();

  const { userId } = useUserProfile();
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const breakpoints = useBreakpoints();
  const { getAccessTokenSilently } = useAuth0();

  // Handle URL search params
  const [searchParams, setSearchParams] = useSearchParams();

  // Convert search params to object and string representations
  const [searchFilters, filterString] = useMemo(() => {
    searchParams.sort();
    return [collectToRecord(searchParams), searchParams.toString()];
  }, [searchParams]);

  // Debounce filter changes to prevent excessive API calls
  const [debouncedFilterString] = useDebounce(filterString, 500);

  // Dialog hooks
  // const {
  //   createDeferred: createNewDealRoomDeferred,
  //   dialog: newDealRoomDialog,
  // } = useNewDealRoomDialog({
  //   organizationSlug: slug!,
  // });

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Decision Site?`,
    subText:
      'Deleting this Decision Site will delete it for all users and cannot be undone. Are you sure you want to delete this Decision Site?',
    primaryAction: 'CANCEL',
  });

  // Infinite scroll setup
  const parentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { ref, inView } = useInView({ root: parentRef.current });

  // Redirect if no organization slug
  useEffect(() => {
    if (!slug) {
      navigate(`/`);
      return;
    }
  }, [slug, isLoading, hasEntitlement, navigate]);

  // Fetch Decision Site data
  const {
    data: dealRoomsData,
    isLoading: dealRoomsLoading,
    isFetched: dealRoomsFetched,
    isFetching: dealRoomsFetching,
    isRefetching: dealRoomsRefetching,
    isFetchingPreviousPage,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    OrganizationDealRoomsQuery(slug!, debouncedFilterString, limit),
    async ({ pageParam }) => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRooms(
        {
          ...searchFilters,
          organizationSlug: slug!,
          limit,
          skip: pageParam || 0,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      getPreviousPageParam: (previousPage) => {
        if (!previousPage?.config?.params?.skip) {
          return undefined;
        }
        return Math.max((previousPage?.config?.params?.skip ?? 0) - limit, 0);
      },
      getNextPageParam: (previousPage) => {
        if (!previousPage?.data?.length || previousPage.data.length < limit) {
          return undefined;
        }
        return (previousPage?.config?.params?.skip ?? 0) + limit;
      },
    },
  );

  // Load next page when scrolling to bottom
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  // Column render functions
  const onRenderName = useCallback(
    (dealRoom: DealRoom) => (
      <div
        onClick={() => {
          navigate(
            `/organization/${slug}/dealroom/${dealRoom.id}`,
          );
        }}
      >
        <Text variant="medium" block>
          {dealRoom.name}
        </Text>
        {dealRoom.description && (
          <Text variant="small" block>
            {dealRoom.description}
          </Text>
        )}
      </div>
    ),
    [navigate, slug],
  );

  const onRenderContacts = useCallback(
    (dealRoom: DealRoom) => {
      if (!dealRoom.contacts?.length) return <></>;

      const personas = dealRoom.contacts
        .filter((c) => !internalDomains.includes(c.emailDomain))
        .map((contact) => ({
          imageUrl: contact.avatarFileUrl || contact.avatarUrl || undefined,
          personaName: contact.name || contact.email,
          data: contact,
        }));

      return <Facepile personas={personas} />;
    },
    [internalDomains],
  );

  const onRenderContact = useCallback(
    (dealRoom: DealRoom) =>
      slug && dealRoom.owner ? (
        <ContactCard
          contact={dealRoom.owner}
          organizationSlug={slug}
          domain={getDomain(dealRoom.owner.email)}
          hideBottomBorder
          hideSocialIcons
          showOnlyAvatar={!breakpoints?.lg}
        />
      ) : (
        <></>
      ),
    [slug, breakpoints],
  );

  const onRenderDelete = useCallback(
    (dealRoom: DealRoom) => {
      const canDelete = isAdmin || dealRoom.owner?.id === userId;
      return (
        <AsyncIconButton
          name="Delete"
          iconProps={DeleteIconProps}
          disabled={!canDelete}
          onClick={async () => {
            try {
              const confirmDelete = await createConfirmDeleteDeferred().promise;
              if (!confirmDelete) return;

              const token = await getAccessTokenSilently();
              await toast.promise(
                DealRoomsApiClient.deleteDealRoom(slug!, dealRoom.id, {
                  headers: { Authorization: `Bearer ${token}` },
                }),
                {
                  loading: 'Deleting Decision Site',
                  success: () => {
                    refetch();
                    appInsights.trackEvent({
                      name: 'DELETE_DEALROOM',
                      properties: {
                        organizationSlug: slug,
                        dealRoomId: dealRoom.id,
                      },
                    });
                    return 'Successfully deleted Decision Site';
                  },
                  error: () => 'Something went wrong deleting Decision Site, please try again later',
                },
              );
            } catch (err) {
              // Error handled by toast
            }
          }}
          text="Delete"
        />
      );
    },
    [
      appInsights,
      createConfirmDeleteDeferred,
      getAccessTokenSilently,
      isAdmin,
      slug,
      refetch,
      userId,
    ],
  );

  // Define table columns
  const dealRoomColumns: IColumn[] = useMemo(
    () =>
      [
        {
          key: 'name',
          name: 'Name',
          minWidth: breakpoints.lg ? 300 : 150,
          fieldName: 'name',
          onRender: onRenderName,
        },
        {
          key: 'contacts',
          name: 'Contacts',
          minWidth: breakpoints.lg ? 500 : 300,
          fieldName: 'contacts',
          onRender: onRenderContacts,
        },
        {
          key: 'owner',
          name: 'Owner',
          minWidth: breakpoints?.lg ? 250 : 32,
          maxWidth: breakpoints?.lg ? 250 : 32,
          fieldName: 'owner',
          className: breakpoints?.md ? 'creator' : 'hidden',
          headerClassName: breakpoints?.md ? 'creator' : 'hidden',
          onRender: onRenderContact,
        },
        {
          key: 'delete',
          name: 'Delete',
          minWidth: 80,
          maxWidth: 80,
          fieldName: 'delete',
          className: 'delete',
          onRender: onRenderDelete,
        },
      ].filter(Truthy),
    [
      breakpoints.lg,
      breakpoints?.md,
      onRenderName,
      onRenderContact,
      onRenderContacts,
      onRenderDelete,
    ],
  );


  // const handleCreateNewDealRoom = async () => {
  //   try {
  //     const newDealRoom = await createNewDealRoomDeferred().promise;
  //     const token = await getAccessTokenSilently();
  //
  //     const createdDealRoom = await DealRoomsApiClient.createDealRoom(
  //       slug!,
  //       {
  //         name: newDealRoom.name,
  //         description: newDealRoom.description,
  //         contacts: newDealRoom.contacts,
  //       },
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       },
  //     );
  //
  //     if (createdDealRoom.data.id) {
  //       appInsights.trackEvent({
  //         name: 'CREATE_DEALROOM',
  //         properties: {
  //           organizationSlug: slug,
  //           dealRoomId: createdDealRoom.data.id,
  //         },
  //       });
  //
  //       navigate(`/organization/${slug}/dealroom/${createdDealRoom.data.id}`);
  //     }
  //   } catch (err) {
  //     if (err instanceof Error) {
  //       toast.error(`Something went wrong creating Decision Site`);
  //     }
  //   }
  // };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <DecisionSiteListControls
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          canCreatePlans={canCreatePlans}
          onCreateNewDealRoom={async () => {}} //handleCreateNewDealRoom
        />

        <DecisionSiteListView
          dealRoomsData={dealRoomsData?.pages}
          dealRoomsFetching={dealRoomsFetching}
          dealRoomsFetched={dealRoomsFetched}
          columns={dealRoomColumns}
          hasNextPage={!!hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          parentRef={parentRef}
          loadMoreRef={ref}
        />
      </div>
      {/*{newDealRoomDialog}*/}
      {confirmDeleteDialog}
    </>
  );
};
