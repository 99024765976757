import { useAuth0 } from '@auth0/auth0-react';
import {
  ContextualMenu,
  ContextualMenuItemType,
  DirectionalHint,
  FontIcon,
  FontSizes,
  FontWeights,
  IContextualMenuItem,
  IContextualMenuProps,
  IContextualMenuStyles,
  Image,
  IPersonaSharedProps,
  mergeStyles,
  NeutralColors,
  Persona,
  PersonaSize,
  TeachingBubble,
  Text,
  TooltipHost,
  useTheme,
} from '@fluentui/react';
import { hasOwnNumberProperty } from '@meetingflow/common/ObjectHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { default as toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useMatch, useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import useCreateMeetingflowModal from '../Hooks/Modals/useNewMeetingflowDialog';
import useRecordUpcomingMeetingsDialog from '../Hooks/Modals/useRecordUpcomingMeetingsDialog';
import { default as useBreakpoints } from '../Hooks/useBreakpoints';
import { useExternalServiceConfigurations } from '../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../Hooks/useLocalStorageState';
import { useOrganization } from '../Hooks/useOrganization';
import { useUserProfile } from '../Hooks/useProfile';
import useWindowFocus from '../Hooks/useWindowFocus';
import { OrganizationsQuery } from '../QueryNames';
import { OrganizationsApiClient } from '../Services/NetworkCommon';
import { default as helpIcon } from '../Static/Icons/help.svg';
import MeetingPlanLogo from '../Static/Images/MeetingPlanLogoPurple.svg';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';
import BillingCta from './BillingCta';
import { LocationAwareLink } from './Common/LocationAwareLink';
import { APP_HEADER_HEIGHT, APP_MAX_WIDTH } from './Layouts/BaseLayout';
import { NewMeetingflowButton } from './MeetingPlans/NewMeetingflowButton';
import { GlobalSearch } from './Organization/GlobalSearch';

const PANDA_WORKSPACES = ['panda-ai', 'meetingflow', 'happycust', 'kyle-zone2'];

export const HeaderNav = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();
  const { user: mfUser, isMeetingflowAdmin } = useUserProfile();
  const breakpoints = useBreakpoints();

  const userRef = useRef(null);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showPipelineAddedBubble, setShowPipelineAddedBubble] = useState(true);
  const onShowUserMenu = useCallback((ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault(); // don't navigate
    setShowUserMenu(true);
  }, []);
  const onHideUserMenu = useCallback(() => setShowUserMenu(false), []);
  const [dismissedCRMPipelineCoachmark, setDismissedCRMPipelineCoachmark] =
    useLocalStorageState<boolean>('DISMISSED_CRM_PIPELINE_COACHMARK', false);

  const location = useLocation();

  const match = useMatch({
    path: '/organization/:organizationSlug',
    end: false,
  });
  const organizationSlug = match?.params.organizationSlug;

  const [searchParams] = useSearchParams();

  const [searchFocused, setSearchFocused] = useState<boolean>(false);

  const {
    createDeferred: createMeetingflowDeferred,
    dialog: createMeetingflowDialog,
  } = useCreateMeetingflowModal();

  const {
    createDeferred: recordUpcomingMeetingsDeferred,
    dialog: recordUpcomingMeetingsDialog,
  } = useRecordUpcomingMeetingsDialog();

  const { hasToken: hasSalesforceToken } = useExternalServiceConfigurations({
    app: 'SALESFORCE',
    withToken: true,
  });

  const { hasToken: hasHubSpotToken } = useExternalServiceConfigurations({
    app: 'HUBSPOT',
    withToken: true,
  });

  const [activeOrgId, setActiveOrgId] = useLocalStorageState<
    number | undefined
  >('active-org-id', undefined);

  const { data: orgData, isLoading: orgDataIsLoading } = useQuery(
    OrganizationsQuery,
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.listOrganizations(
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const activeOrg = useMemo(() => {
    if (
      organizationSlug !== undefined &&
      organizationSlug.toLowerCase() !== 'create'
    ) {
      return orgData?.data?.find((org) => org.slug === organizationSlug);
    }
    if (activeOrgId !== undefined) {
      return orgData?.data?.find((o) => o.id === activeOrgId);
    }
    return undefined;
  }, [activeOrgId, orgData?.data, organizationSlug]);

  const {
    isGuest: isOrgGuest,
    isAdmin: isOrgAdmin,
    canCreatePlans,
    role,
    hasEntitlement,
  } = useOrganization(activeOrg?.slug);

  const isWindowFocused = useWindowFocus();

  useEffect(() => {
    if (isWindowFocused && !orgDataIsLoading && orgData?.data?.length) {
      if (!!activeOrg && activeOrg.id !== activeOrgId) {
        setActiveOrgId(activeOrg.id);
      } else if (
        organizationSlug &&
        organizationSlug.toLowerCase() !== 'create' &&
        !activeOrg &&
        orgData.data[0].id !== activeOrgId
      ) {
        setActiveOrgId(orgData.data[0].id);
      }
    }
  }, [
    orgData,
    activeOrg,
    activeOrgId,
    setActiveOrgId,
    orgDataIsLoading,
    organizationSlug,
    isWindowFocused,
  ]);

  // Open the record upcoming meetings dialog if the user is visiting for the first time
  useEffect(() => {
    if (
      searchParams.get('firstVisit') &&
      canCreatePlans &&
      recordUpcomingMeetingsDeferred &&
      organizationSlug &&
      createMeetingflowDeferred
    ) {
      if (PANDA_WORKSPACES.includes(organizationSlug)) {
        recordUpcomingMeetingsDeferred();
      } else {
        createMeetingflowDeferred();
      }
    }
  }, [
    recordUpcomingMeetingsDeferred,
    organizationSlug,
    createMeetingflowDeferred,
    searchParams,
    canCreatePlans,
  ]);

  const headerNavStyle = mergeStyles({
    height: `calc(${APP_HEADER_HEIGHT} - 1rem)`,
    padding: '0 .5rem',
    ':first-of-type': {
      marginLeft: breakpoints?.md ? '1rem' : 0,
    },
    transition: 'all .3s ease-in-out',
    color: isDark ? theme.palette.black : MEETINGFLOW_COLORS.purpleDarkest,
    fontSize: `${FontSizes.large} !important`,
    fontWeight: FontWeights.semibold,
    minWidth: breakpoints.md ? '4rem' : '3rem',
    backgroundColor: 'transparent',
    borderRadius: '.25rem',
    textDecoration: 'none',
    borderWidth: 0,
    position: 'relative',
    top: '.125rem',
    span: {
      lineHeight: '2.5rem !important',
      display: 'inline-block',
      textAlign: 'center',
    },
    '.ms-Button-flexContainer': {
      height: '100%',
    },
    '.ms-Button-textContainer': {
      display: !breakpoints.md ? 'none' : 'inline-block',
      position: 'relative',
      top: '0',
    },
    ':hover span': {
      textDecoration: 'none',

      '.user-role': {
        color: isDark
          ? MEETINGFLOW_COLORS.white
          : MEETINGFLOW_COLORS.purpleDark,
      },
    },
    'i.ms-Button-icon': {
      position: 'relative',
      top: '.05rem',
    },
    '&.ms-Button--hasMenu i.ms-Button-icon': {
      position: 'relative',
      top: '.15rem',
    },
    '&.active, &.active span': {
      color: isDark
        ? MEETINGFLOW_COLORS.white
        : MEETINGFLOW_COLORS.purpleDarkest,
    },

    '&.active, :active, :hover, :disabled': {
      boxShadow: '0 0 2px inset rgba(0,0,0,.05)',
      backgroundColor: isDark
        ? NeutralColors.gray160
        : MEETINGFLOW_COLORS.purpleGrey,
    },

    '&.is-expanded': {
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleDark
        : MEETINGFLOW_COLORS.purpleLight,

      '.user-role': {
        color: isDark
          ? MEETINGFLOW_COLORS.white
          : MEETINGFLOW_COLORS.purpleDark,
      },
    },

    '&.no-workspace-back-button': {
      backgroundColor: `${
        isDark ? MEETINGFLOW_COLORS.purpleDark : MEETINGFLOW_COLORS.purpleGrey
      } !important`,
      color: `${
        isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.purpleSecondary
      } !important`,
      padding: '0 .5rem',
      minWidth: 0,

      i: {
        fontWeight: FontWeights.semibold,
      },
      ':hover': {
        backgroundColor: `${MEETINGFLOW_COLORS.purpleSecondary} !important`,
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
    },
  });

  const headerCreateButtonClass = mergeStyles({
    display: 'inline-block',
    position: 'absolute',
    top: '.4rem',
    right: '4rem',

    span: {
      lineHeight: '2.5rem !important',
    },
  });

  const getOrgOptions = useCallback(() => {
    const orgOptions =
      orgData?.data?.map((org) => ({
        key: org.id.toString(),
        slug: org.slug,
        text: org.name,
        href: `/organization/${org.slug}`,
      })) || [];

    orgOptions.push({
      key: 'divider',
      // @ts-ignore
      itemType: ContextualMenuItemType.Divider,
    });

    orgOptions.push({
      key: 'create-workspace',
      text: 'Create New Workspace...',
      slug: '',
      href: '/organization/create',
    });
    return orgOptions;
  }, [orgData?.data]);

  const menuStyles = {
    root: {
      backgroundColor: isDark
        ? NeutralColors.gray170
        : MEETINGFLOW_COLORS.purpleUltraLight,
      '.ms-ContextualMenu-header': {
        height: '1rem !important',
        paddingTop: '.25rem',
        fontSize: FontSizes.mini,
        textTransform: 'uppercase',
        fontWeight: FontWeights.semibold,
        color: NeutralColors.gray110,

        i: { display: 'none' },
      },
      '.ms-ContextualMenu-divider': {
        backgroundColor: isDark
          ? NeutralColors.gray150
          : MEETINGFLOW_COLORS.purpleLighter,
      },
      selectors: {
        button: {
          transition: '.3s ease-in-out',
          height: '2rem',
          ':hover': {
            backgroundColor: MEETINGFLOW_COLORS.purpleLight,
          },
          i: {
            transition: '.3s ease-in-out',
          },
        },
        'button.is-expanded': {
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleDarkest,
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.purpleSecondary
            : MEETINGFLOW_COLORS.purpleLight,
        },
        'button.is-expanded i': {
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleDarkest,
        },
        'button:hover': {
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.purpleDark
            : MEETINGFLOW_COLORS.purpleLight,

          i: {
            color: `${
              isDark
                ? MEETINGFLOW_COLORS.white
                : MEETINGFLOW_COLORS.purpleDarkest
            } !important`,
          },
        },
        a: {
          transition: '.3s ease-in-out',
          height: '2rem',
          ':hover': {
            backgroundColor: MEETINGFLOW_COLORS.purpleLight,
            textDecoration: 'none',
          },
          i: {
            transition: '.3s ease-in-out',
          },
        },
        'a.is-expanded': {
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleDarkest,
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.purpleSecondary
            : MEETINGFLOW_COLORS.purpleLight,
        },
        'a.is-expanded i': {
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleDarkest,
        },
        'a:hover': {
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.purpleDark
            : MEETINGFLOW_COLORS.purpleLight,

          i: {
            color: `${
              isDark
                ? MEETINGFLOW_COLORS.white
                : MEETINGFLOW_COLORS.purpleDarkest
            } !important`,
          },
        },
      },
    },
  } as Partial<IContextualMenuStyles>;

  const userRoleClass = mergeStyles({
    display: 'block',
    position: 'absolute',
    top: '-.85rem',
    left: '2rem',
    fontSize: `${FontSizes.size10} !important`,
    textTransform: 'lowercase',
    fontVariant: 'small-caps',
    fontWeight: FontWeights.semibold,
    color: NeutralColors.gray90,
    transition: '.3s ease-in-out all',
  });

  const onContextItemClick = useMemo(() => {
    (
      e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
      item?: IContextualMenuProps & { href?: string },
    ) => {
      if (!item?.href) {
        return false;
      }

      if (
        item &&
        item.href &&
        !e.ctrlKey &&
        !e.metaKey &&
        !e.shiftKey &&
        (!hasOwnNumberProperty(e, 'button') || e.button === 0)
      ) {
        e.preventDefault();
        navigate(item.href);
      }

      return true;
    };
  }, [navigate]);

  const headerNav: React.ReactNode[] =
    !!organizationSlug &&
    organizationSlug.toLowerCase() !== 'create' &&
    !isOrgGuest
      ? [
          <LocationAwareLink
            id="meetingflow-app-header-content-library-button"
            className={headerNavStyle}
            key="Library"
            label={
              breakpoints?.md ? (
                <>
                  <span className={classNames(userRoleClass, 'user-role')}>
                    {role}
                  </span>
                  {activeOrg?.name || organizationSlug || 'No Workspace'}
                </>
              ) : undefined
            }
            path={`/organization/${organizationSlug}/library`}
            componentProps={{
              iconProps: {
                iconName: 'CollapseMenu',
              },
              menuProps: {
                styles: menuStyles,
                className: 'app-workspace-menu',
                calloutProps: {
                  className: 'testing',
                  styles: {
                    calloutMain: {
                      overflow: 'hidden !important',
                    },
                  },
                },
                items: [
                  {
                    key: 'meetingflows-header',
                    text: 'Meetingflows',
                    itemType: ContextualMenuItemType.Header,
                  },
                  {
                    key: 'meetingflows',
                    text: 'Library',
                    href: activeOrg?.slug
                      ? `/organization/${activeOrg.slug}/library/plans`
                      : undefined,
                    iconProps: {
                      iconName: 'Library',
                    },
                    onItemClick: onContextItemClick,
                  },
                  {
                    key: 'templates',
                    text: 'Template Editor',
                    disabled: !canCreatePlans,
                    href: activeOrg?.slug
                      ? `/organization/${activeOrg.slug}/library/templates`
                      : undefined,
                    iconProps: {
                      iconName: 'FileTemplate',
                    },
                    onItemClick: onContextItemClick,
                  },
                  ...(hasSalesforceToken() || !hasHubSpotToken()
                    ? [
                        {
                          key: 'salesforce-header',
                          text: 'Salesforce',
                          itemType: ContextualMenuItemType.Header,
                        },
                        {
                          key: 'sf-pipeline',
                          text: 'Opportunity Pipeline',
                          disabled: !hasSalesforceToken(),
                          href: activeOrg?.slug
                            ? `/organization/${activeOrg.slug}/salesforce/pipeline`
                            : undefined,
                          iconProps: {
                            iconName: 'TableComputed',
                          },
                          title: hasSalesforceToken()
                            ? 'View your Salesforce Opportunity Pipeline'
                            : 'Add Salesforce to view your Salesforce Opportunity Pipeline',
                          onItemClick: onContextItemClick,
                        },
                      ]
                    : []),
                  ...(hasHubSpotToken() || !hasSalesforceToken()
                    ? [
                        {
                          key: 'hubspot-header',
                          text: 'HubSpot',
                          itemType: ContextualMenuItemType.Header,
                        },
                        {
                          key: 'hs-pipeline',
                          text: 'Deal Pipeline',
                          disabled: !hasHubSpotToken(),
                          href: activeOrg?.slug
                            ? `/organization/${activeOrg.slug}/hubspot/pipeline`
                            : undefined,
                          iconProps: {
                            iconName: 'TableComputed',
                          },
                          title: hasHubSpotToken()
                            ? 'View your HubSpot Deal Pipeline'
                            : 'Add Salesforce to view your HubSpot Deal Pipeline',
                          onItemClick: onContextItemClick,
                        },
                      ]
                    : []),
                  {
                    key: 'relationships-header',
                    text: 'Relationships',
                    itemType: ContextualMenuItemType.Header,
                  },
                  {
                    key: 'companies',
                    text: 'Companies',
                    href: activeOrg?.slug
                      ? `/organization/${activeOrg.slug}/library/companies`
                      : undefined,
                    iconProps: {
                      iconName: 'CompanyDirectory',
                    },
                    onItemClick: onContextItemClick,
                  },
                  {
                    key: 'contacts',
                    text: 'Contacts',
                    href: activeOrg?.slug
                      ? `/organization/${activeOrg.slug}/library/contacts`
                      : undefined,
                    iconProps: {
                      iconName: 'Contact',
                    },
                    onItemClick: onContextItemClick,
                  },
                  {
                    key: 'divider-1',
                    text: 'Divider',
                    itemType: ContextualMenuItemType.Divider,
                  },
                  {
                    key: 'workspace-settings-members',
                    text: isOrgAdmin ? 'Settings and Members' : 'Settings',
                    href: activeOrg?.slug
                      ? `/organization/${activeOrg.slug}/settings`
                      : undefined,
                    iconProps: {
                      iconName: 'Settings',
                    },
                    onItemClick: onContextItemClick,
                  },
                  {
                    key: 'workspace-usage',
                    text: 'View Usage Report',
                    disabled: !isOrgAdmin,
                    href: activeOrg?.slug
                      ? `/organization/${activeOrg.slug}/usage`
                      : undefined,
                    iconProps: {
                      iconName: 'AnalyticsView',
                    },
                    onItemClick: onContextItemClick,
                  },
                  {
                    key: 'divider-2',
                    text: '-',
                    itemType: ContextualMenuItemType.Header,
                  },
                  {
                    key: 'workspaces',
                    text: 'Switch Workspace...',
                    iconProps: {
                      iconName: 'Org',
                    },
                    subMenuProps: {
                      styles: menuStyles,
                      items: getOrgOptions(),
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onItemClick: (
                        e:
                          | React.MouseEvent<HTMLElement>
                          | React.KeyboardEvent<HTMLElement>,
                        item: {
                          key: string;
                          slug: string;
                          text: string;
                          href: string;
                        },
                      ) => {
                        if (
                          item &&
                          item.href &&
                          !e.ctrlKey &&
                          !e.metaKey &&
                          !e.shiftKey &&
                          (!hasOwnNumberProperty(e, 'button') || e.button === 0)
                        ) {
                          e.preventDefault();

                          if (!item.slug) {
                            setActiveOrgId(parseInt(item.key));
                          }

                          navigate(item.href);
                        }

                        return true;
                      },
                    },
                  },
                ].filter(Truthy),
              },
            }}
            asButton
          />,
          <LocationAwareLink
            key="Home"
            id="meetingflow-app-header-content-home-button"
            className={headerNavStyle}
            label="Dashboard"
            path={`/organization/${organizationSlug}`}
            exact
            componentProps={{
              iconProps: {
                iconName: 'Home',
              },
            }}
            asButton
          />,
        ]
      : location.pathname !== '/'
        ? [
            <LocationAwareLink
              id="meetingflow-app-header-content-home-button"
              key="Back"
              className={classNames(headerNavStyle, 'no-workspace-back-button')}
              path={location?.state?.from || '/'}
              exact
              componentProps={{
                iconProps: {
                  iconName: 'Back',
                },
              }}
              asButton
            />,
            <LocationAwareLink
              id="meetingflow-app-header-content-library-button"
              className={classNames(
                headerNavStyle,
                'no-workspace-workspaces-menu',
              )}
              key="Library"
              label={'Your Workspaces'}
              path={`/organization/${organizationSlug}/library`}
              componentProps={{
                iconProps: {
                  iconName: 'CollapseMenu',
                },
                menuProps: {
                  styles: menuStyles,
                  className: 'app-workspace-menu',
                  calloutProps: {
                    className: 'testing',
                    styles: {
                      calloutMain: {
                        overflow: 'hidden !important',
                      },
                    },
                  },
                  items: getOrgOptions(),
                  onItemClick: (
                    _event: unknown,
                    item: { key: string; slug: string; text: string },
                  ) => {
                    if (!item?.slug) {
                      // If there's no slug we're creating
                      return navigate('/organization/create');
                    }
                    setActiveOrgId(parseInt(item.key));
                    navigate(`/organization/${item.slug}`);
                  },
                },
              }}
              asButton
            />,
          ]
        : [];

  if (hasSalesforceToken() || hasHubSpotToken()) {
    headerNav.push(
      <>
        <LocationAwareLink
          key="Pipeline"
          id="meetingflow-app-header-content-pipeline-button"
          className={headerNavStyle}
          label="Pipeline"
          path={
            hasSalesforceToken()
              ? `/organization/${organizationSlug}/salesforce/pipeline`
              : `/organization/${organizationSlug}/hubspot/pipeline`
          }
          exact
          componentProps={{
            iconProps: {
              iconName: 'TableComputed',
            },
          }}
          asButton
        />
        {showPipelineAddedBubble && !dismissedCRMPipelineCoachmark ? (
          <TeachingBubble
            target={`#meetingflow-app-header-content-pipeline-button`}
            ariaDescribedBy="Edit Meetingflow Details"
            ariaLabelledBy="Edit Meetingflow Details"
            hasCondensedHeadline
            onDismiss={() => {
              setShowPipelineAddedBubble(false);
              setDismissedCRMPipelineCoachmark(true);
            }}
            hasCloseButton
            styles={{
              root: {
                '*': {
                  color: 'white !important',
                },
                borderRadius: '.5rem',
                backgroundColor: MEETINGFLOW_COLORS.teal,
                '.ms-Callout-main': {
                  borderRadius: '.5rem',
                  // maxHeight:
                  // isUnassociatedWithEventAndNew &&
                  // !dismissedEditDetailsCoachmark
                  //   ? '4.5rem !important'
                  //   : '2.25rem !important',
                  backgroundColor: `${MEETINGFLOW_COLORS.teal} !important`,
                },
                '.ms-Callout-beak': {
                  backgroundColor: `${MEETINGFLOW_COLORS.teal} !important`,
                  maxHeight: '2rem',
                },
                '.ms-Callout-beakCurtain': {
                  backgroundColor: `${MEETINGFLOW_COLORS.teal} !important`,
                  borderRadius: '.5rem',
                },
                '.ms-TeachingBubble-header': {
                  margin: 0,
                },
                '.ms-TeachingBubble-bodycontent': {
                  padding: '.5rem',
                },
                '.ms-TeachingBubble-closebutton': {
                  margin: 0,
                },
                '.ms-TeachingBubble-body': {
                  // lineHeight:
                  //   isUnassociatedWithEventAndNew &&
                  //   !dismissedEditDetailsCoachmark
                  //     ? undefined
                  //     : '1.35rem',
                  margin: '0 1rem 0 0',
                },
              },
            }}
            calloutProps={{
              directionalHint: DirectionalHint.bottomCenter,
              backgroundColor: MEETINGFLOW_COLORS.teal,
            }}
          >
            <Text>
              <FontIcon
                iconName="Info"
                style={{
                  height: '64px',
                  width: '32px',
                  float: 'left',
                  fontSize: '24px',
                }}
              />

              <>
                <strong
                  style={{
                    fontWeight: FontWeights.semibold,
                  }}
                >
                  Now that you've connected your CRM to Meetingflow, check out
                  our new Pipeline view!
                </strong>{' '}
              </>
            </Text>
          </TeachingBubble>
        ) : null}
      </>,
    );
  }

  const userMenuItems: IContextualMenuItem[] = [
    {
      key: 'profile',
      text: 'Your Profile',
      iconProps: {
        iconName: 'AccountBrowser',
      },
      onClick: () => navigate('/profile'),
    },
    organizationSlug
      ? {
          key: 'workspace-settings',
          text: 'Your Workspace Preferences',
          iconProps: {
            iconName: 'ContactCardSettings',
          },
          onClick: () =>
            navigate(`/organization/${organizationSlug}/settings?tab=user`),
        }
      : undefined,
    {
      key: 'invites',
      text: 'Your Workspace Invites',
      iconProps: {
        iconName: 'ChatInviteFriend',
      },
      onClick: () => navigate('/invites'),
    },
    ...(isMeetingflowAdmin
      ? [
          {
            key: '-1',
            text: '-',
          },

          {
            key: 'email preview',
            text: 'Email preview tool',
            iconProps: {
              iconName: 'Mail',
            },
            onClick: () => navigate('/emailPreview'),
          },
          {
            key: 'admin billing',
            text: 'Billing Admin',
            iconProps: {
              iconName: 'Money',
            },
            onClick: () => navigate('/adminBilling'),
          },
          {
            key: 'adming recent usage',
            text: 'Admin Usage Summary',
            iconProps: {
              iconName: 'LineChart',
            },
            onClick: () => navigate('/admin-usage-summary'),
          },
          {
            key: 'admin usage',
            text: 'Admin Workspace Usage',
            iconProps: {
              iconName: 'LineChart',
            },
            onClick: () => navigate('/adminUsage'),
          },
        ]
      : []),
    ...(import.meta.env.DEV
      ? [
          {
            key: '-2',
            text: '-',
          },
          {
            key: 'copy token',
            text: 'Copy bearer token',
            iconProps: {
              iconName: 'Copy',
            },
            onClick: () =>
              getAccessTokenSilently().then((token) => {
                navigator?.clipboard
                  ?.writeText(token)
                  .then(() => toast(`Bearer token copied to clipboard`));
              }),
          },
        ]
      : []),

    {
      key: '-3',
      text: '-',
    },
    {
      key: 'sign-out',
      text: 'Sign out',
      iconProps: {
        iconName: 'SignOut',
      },
      onClick: () => navigate('/logout'),
    },
  ].filter(Truthy) as IContextualMenuItem[];

  const userPersona: IPersonaSharedProps = useMemo(
    () => ({
      text: mfUser?.name || user?.name,
      imageUrl: mfUser?.avatarFileUrl || mfUser?.avatarUrl || user?.picture,
    }),
    [
      mfUser?.avatarFileUrl,
      mfUser?.avatarUrl,
      mfUser?.name,
      user?.name,
      user?.picture,
    ],
  );

  const appHeaderClass = mergeStyles({
    backgroundColor: isDark ? '#090808' : MEETINGFLOW_COLORS.white,
    borderBottom: isDark
      ? `1px solid ${NeutralColors.gray170}`
      : `1px solid ${MEETINGFLOW_COLORS.purpleGrey}`,
    display: 'block',
    height: APP_HEADER_HEIGHT,
    width: '100%',
    position: 'sticky',
    top: `0 !important`,
    containerType: 'inline-size',

    '.meetingflow-app-header-content': {
      width: '100%',
      height: APP_HEADER_HEIGHT,
      maxWidth: APP_MAX_WIDTH,
      boxSizing: 'border-box',
      padding: 0,
      margin: '0 auto',
      position: 'relative',
    },

    '.app-title': {
      position: 'absolute',
      cursor: 'pointer',
      left: '1rem',
      span: {
        fontSize: FontSizes.xLarge,
        fontWeight: FontWeights.semibold,
        lineHeight: APP_HEADER_HEIGHT,
      },
      '.ms-Image': {
        float: 'left',
        marginTop: '.625rem',
      },
    },

    '.app-menu': {
      position: 'absolute',
      top: '.5rem',
      height: `calc(${APP_HEADER_HEIGHT} - 2rem)`,
      padding: '.25rem',
      transition: 'background-color .3s ease-in-out',
      borderRadius: '.25rem',
      ':hover': {
        backgroundColor: isDark
          ? NeutralColors.gray160
          : MEETINGFLOW_COLORS.purpleSecondary,

        '> .app-menu-chevron': {
          color: isDark
            ? `${MEETINGFLOW_COLORS.white} !important`
            : `${MEETINGFLOW_COLORS.white} !important`,
        },

        '.ms-Persona-details *': {
          color: isDark
            ? `${MEETINGFLOW_COLORS.white} !important`
            : `${MEETINGFLOW_COLORS.white} !important`,
        },
      },
      '.ms-Persona-initials': {
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
      '> .app-menu-chevron': {
        color: isDark
          ? `${MEETINGFLOW_COLORS.white} !important`
          : `${MEETINGFLOW_COLORS.purpleDarkest} !important`,
      },

      '.ms-Persona-details *': {
        color: isDark
          ? `${MEETINGFLOW_COLORS.white} !important`
          : `${MEETINGFLOW_COLORS.black} !important`,
      },
    },

    '.app-menu-chevron': {
      position: 'absolute',
      top: '.8rem',
      right: '.5rem',
      color: 'white !important',
    },

    '.app-user-menu': {
      right: '.5rem',
      height: '2rem',
      backgroundColor: showUserMenu
        ? isDark
          ? theme.palette.neutralLighterAlt
          : MEETINGFLOW_COLORS.purpleSecondary
        : isDark
          ? NeutralColors.gray180
          : MEETINGFLOW_COLORS.purpleGrey,
      '.app-menu-chevron': {
        color: showUserMenu ? 'black !important' : 'white !important',
      },
    },

    '.app-toggle-theme': {
      position: 'absolute',
      top: '.5rem',
      right: '18rem',
      width: '2rem',
      height: APP_HEADER_HEIGHT,
      paddingTop: '1rem',
    },

    '.app-header-icon-button': {
      backgroundColor: theme.palette.themePrimary,
    },

    '.app-nav-links': {
      marginLeft: '0',
      marginTop: breakpoints?.md ? '.35rem' : 0,
      width: 'auto',
      minWidth: breakpoints.lg ? '10rem' : undefined,
      display: 'inline-block',

      button: {
        marginRight: breakpoints?.md ? '.5rem' : '0',
        padding: breakpoints?.md ? undefined : '.25rem',
        minWidth: breakpoints?.md ? undefined : '2rem',
      },

      '#meetingflow-app-header-content-library-button': {
        margin: breakpoints?.md ? undefined : 0,
        padding: breakpoints?.md ? undefined : '.25rem',
      },

      span: {
        fontSize: 'unset',
        fontWeight: FontWeights.semibold,
        lineHeight: 0,
      },
    },
  });

  const logo = MeetingPlanLogo;

  return (
    <>
      <BillingCta />
      <div id="meetingflow-app-header" className={appHeaderClass}>
        <div
          id="meetingflow-app-header-content"
          className="meetingflow-app-header-content"
        >
          <div id="meetingflow-app-header-content-title" className="app-title">
            {breakpoints.md ? (
              <LocationAwareLink
                path={
                  !!activeOrg?.slug ? `/organization/${activeOrg.slug}` : '/'
                }
              >
                <Image
                  src={logo}
                  style={{
                    height: '34px',
                    width: '34px',
                  }}
                />
              </LocationAwareLink>
            ) : null}

            <div className="app-nav-links">{headerNav}</div>
          </div>

          <div>
            <div
              style={{
                display: organizationSlug ? 'block' : 'none',
                position: 'absolute',
                right: breakpoints.lg
                  ? mfUser?.preferenceNewMeetingflowDialog === 'ENABLED'
                    ? '16rem'
                    : '18.25rem'
                  : breakpoints?.md
                    ? mfUser?.preferenceNewMeetingflowDialog === 'ENABLED'
                      ? '8rem'
                      : '10.25rem'
                    : mfUser?.preferenceNewMeetingflowDialog === 'ENABLED'
                      ? '6rem'
                      : '8.25rem',
                top: '.75rem',
                width: 'auto',
                transition: 'all .3s ease-in-out',
                height: '2rem',
                maxWidth: searchFocused ? '100%' : '4rem',
                overflow: 'hidden',
                textAlign: 'right',
              }}
            >
              <GlobalSearch
                onFocusSearch={() => setSearchFocused(true)}
                onBlurSearch={() => setSearchFocused(false)}
              />
            </div>
            <div
              style={{
                display: breakpoints?.md ? 'block' : 'none',
                position: 'absolute',
                right: organizationSlug
                  ? breakpoints.lg
                    ? mfUser?.preferenceNewMeetingflowDialog === 'ENABLED'
                      ? '15rem'
                      : '17.25rem'
                    : mfUser?.preferenceNewMeetingflowDialog === 'ENABLED'
                      ? '7rem'
                      : '9.25rem'
                  : '4.5rem',
                top: '.85rem',
                width: '1rem',
              }}
            >
              <TooltipHost
                directionalHint={DirectionalHint.bottomCenter}
                color={NeutralColors.black}
                calloutProps={{
                  styles: {
                    root: {
                      padding: '.75rem',
                      marginTop: '-.25rem',
                    },
                  },
                }}
                tooltipProps={{
                  styles: {
                    root: {
                      color: NeutralColors.white,
                      display: 'inline-block',
                    },
                  },
                }}
                content={<Text block>Help and Documentation</Text>}
              >
                <a
                  id="meetingflow-app-header-content-help-button"
                  href="https://meetingflow.com/help-center/"
                  rel="noreferrer noopener"
                  target="_blank"
                  style={{
                    height: '1.25rem',
                    width: '1.25rem',
                    borderRadius: '.75rem',
                    display: 'inline-block',
                    position: 'relative',
                    top: '.25rem',
                    marginLeft: '.5rem',
                  }}
                >
                  <img
                    src={helpIcon}
                    alt="Help"
                    style={{
                      color: MEETINGFLOW_COLORS.purpleDark,
                      fontWeight: '900',
                      textAlign: 'center',
                      display: 'inline-block',
                      height: '1.25rem',
                      width: '1.25rem',
                      position: 'relative',
                      fontSize: FontSizes.large,
                      fill: MEETINGFLOW_COLORS.purpleDark,
                    }}
                  />
                </a>
              </TooltipHost>
            </div>
            {organizationSlug ? (
              <div key="Create Meetingflow" className={headerCreateButtonClass}>
                <NewMeetingflowButton
                  organizationSlug={organizationSlug}
                  createMeetingflowDeferred={createMeetingflowDeferred}
                  showDialog={
                    mfUser?.preferenceNewMeetingflowDialog === 'ENABLED'
                  }
                />
              </div>
            ) : null}
          </div>

          <div
            id="meetingflow-header-user-menu"
            className="app-menu app-user-menu"
            ref={userRef}
            onClick={onShowUserMenu}
          >
            <Persona
              {...userPersona}
              size={PersonaSize.size32}
              hidePersonaDetails={true}
            />
          </div>
        </div>{' '}
        : null]
        <ContextualMenu
          items={userMenuItems}
          styles={menuStyles}
          hidden={!showUserMenu}
          target={userRef}
          onItemClick={onHideUserMenu}
          onDismiss={onHideUserMenu}
          className="app-contextual-menu"
          gapSpace={6}
          alignTargetEdge
          directionalHint={DirectionalHint.bottomRightEdge}
        />
      </div>

      {createMeetingflowDialog}
      {recordUpcomingMeetingsDialog}
    </>
  );
};

export default HeaderNav;
